var _sentryCollisionFreeGlobalObject=typeof window!="undefined"?window:typeof global!="undefined"?global:typeof self!="undefined"?self:{};_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"]=undefined;_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"]={"id":"w8dBgoc54brfymVFArFNP"};_sentryCollisionFreeGlobalObject["__sentryBasePath"]=undefined;_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"]="";// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import*as Sentry from"@sentry/nextjs";import{SENTRY_DETAILS}from"./next.config";// * Sync between sentry.{client|server}.config.ts
var whitelists=[{requestUrl:new RegExp("^/login$"),type:new RegExp("AxiosError"),value:new RegExp("Request failed with status code 400")},{requestUrl:new RegExp(".*"),type:new RegExp("Error"),value:new RegExp("Abort fetching component for route")},{requestUrl:new RegExp("/_next/static/chunks/pages/.*\\.js"),type:new RegExp("Error"),value:new RegExp("Failed to load script")}];var tracesSampler=function tracesSampler(_ref){var name=_ref.name,parentSampled=_ref.parentSampled;// These are important - take a big sample
if(name.includes("auth"))return 1;if(name.includes("kyc"))return 1;if(name.includes("verify"))return 1;// These are less important or happen much more frequently - only take 1%
if(name.includes("healthcheck"))return 0.01;if(name.includes("comment"))return 0.01;// Continue trace decision, if there is any parentSampled information
if(typeof parentSampled==="boolean")return parentSampled;// Else, use default sample rate
return 0.5;};var beforeSend=function beforeSend(event,hint){var _event$message,_event$request,_event$exception,_event$exception2;// Ignore "Timeout" thrown by Google reCaptcha as it doesn't impact end user https://github.com/getsentry/sentry-javascript/issues/2514
if(hint.originalException==="Timeout")return null;// https://fundnel.atlassian.net/browse/AG-4999
if((_event$message=event.message)!==null&&_event$message!==void 0&&_event$message.includes("[next-auth][error][CLIENT_FETCH_ERROR]")){return null;}var requestUrl=(_event$request=event.request)===null||_event$request===void 0?void 0:_event$request.url;var type=(_event$exception=event.exception)===null||_event$exception===void 0||(_event$exception=_event$exception.values)===null||_event$exception===void 0||(_event$exception=_event$exception[0])===null||_event$exception===void 0?void 0:_event$exception.type;var value=(_event$exception2=event.exception)===null||_event$exception2===void 0||(_event$exception2=_event$exception2.values)===null||_event$exception2===void 0||(_event$exception2=_event$exception2[0])===null||_event$exception2===void 0?void 0:_event$exception2.value;// If either of these are missing, return the event as is
if(!requestUrl||!type||!value)return event;// If the event matches the whitelist, return null to drop it
if(whitelists.some(function(w){return w.requestUrl.test(requestUrl)&&w.type.test(type)&&w.value.test(value);})){console.info("[Sentry] Filtering out event: ".concat(type," - ").concat(value));return null;}// Otherwise, return the event as is
return event;};// * END Sync between sentry.{client|edge|server}.config.ts
Sentry.init({dsn:SENTRY_DETAILS.SENTRY_DSN,tracesSampler:tracesSampler,// Setting this option to true will print useful information to the console while you're setting up Sentry.
debug:false,replaysOnErrorSampleRate:1.0,// This sets the sample rate to be 10%. You may want this to be 100% while
// in development and sample at a lower rate in production
replaysSessionSampleRate:0.1,// You can remove this option if you're not planning to use the Sentry Session Replay feature:
integrations:[Sentry.replayIntegration({// Additional Replay configuration goes in here, for example:
maskAllText:true,blockAllMedia:true}),Sentry.captureConsoleIntegration({levels:["error"]})],beforeSend:beforeSend});